import Vue from "vue"
import VueRouter from "vue-router"
import Home from '@/components/pages/Home.vue'
import Work from '@/components/pages/Work.vue'
/* import Contact from '@/pages/Contact.vue'
*/
Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/work',
            name: 'work',
            component: Work
        },
         /* 
        {
            path: '/contact',
            name: 'contact',
            component: Contact
        },
        {
            path: '/projects/',
            name: 'projects',
            component: Projects
        },
        */
    ]
})