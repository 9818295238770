<template>
  <div class="columns is-multiline has-background-white-bis">
    <section class="section">
      <div class="column">
        <!-- Start of tiles -->
        <div class="tile is-ancestor">
          <div class="tile is-4 is-vertical is-parent">
            <div class="tile is-child notification is-primary is-light">
              <p class="title">About</p>
              <p>
                Creating great experiences for technology communities with a special interest in video, media, and UXD.
              </p>
              <br>
              <p>
                Offline, you can find me drinking coffee or whiskey, knitting, entertaining cats, listening to a wild range of music, mending clothes, and trying to live more sustainably.  
              </p>
            </div>
              <!-- <div class="tile is-child notification is-info is-light">
                <p class="title">Projects</p>
                <p>
                  I've built a number of projects recently, click below to find out more.
                </p>
                <br>
                <b-button tag="router-link" :to="{name: 'projects'}" type="is-info">View all projects</b-button>
              </div> -->
            <div class="tile is-child notification is-warning is-light">
              <p class="title">Connect with me</p>
              <p> 
                Where am I on the web?
                <ul>
                    <li> <a href="https://bsky.app/profile/dalekmad.bsky.social">Bluesky</a></li>
                    <li> <a href="https://mastodon.ie/@dalekmad">Mastodon</a></li>
                    <li> <a href="https://www.instagram.com/dalekmad/">Instagram</a></li>
                    <li> <a href="https://www.tiktok.com/@echoingstem?lang=en">TikTok</a></li>
                    <li> <a href="https://www.linkedin.com/in/dalekmad/">LinkedIn</a></li>
                    <li> <s> <a href="https://twitter.com/dalekmad">Twitter</a></s> <i>After 17 years, I'm no longer posting.</i></li>
                </ul>
              </p>
            </div>
            <div class="tile is-child notification is-danger is-light">
              <p class="title">Projects</p>
              <p> 
                <ul>
                  <li class="is-size-6"> <a href="https://www.echoingstem.com">Echoing STEM</a>: Creating digital content through a STEMinist lens.</li>  
                  <br>
                   <li class="is-size-6"> <a href="https://www.shop.vanessagreene.ie">Shop</a>: Selling art projects of mine.</li> 
                   <br>
                   <li class="is-size-6"> <a href="https://www.instagram.com/themendinggreen/">The Mending Green</a>: Fostering a community menders and makers, inspiring a circular economy. </li>
                </ul>
              </p>

            </div>
          </div>
          <div class="tile is-vertical is-parent">
            <div class="tile is-child notification is-light">
              <p class="title">Experience</p>
              <ul>
                <li
                v-for="experience in experience"
                :key="experience.id"
                >  
                  <p class="title is-6 "> {{ experience.role }} | {{ experience.company }} </p>
                 <!--<p class="subtitle is-6 "> {{ experience.description }} </p> -->
                  <br>
                </li>
              </ul>
            </div>
            <div class="tile is-child notification is-success is-light">
              <p class="title">Education &amp; Qualifications </p>
              <ul>
                <li>
                  <p class="title is-6 "> BSc in Creative Computing | IADT, Dublin </p>
                </li>
                <br>
                <li>
                  <p class="title is-6 "> Certificate in User Research &amp; UX Design | IADT, Dublin </p>
                </li>
                <br>
                <li>
                  <p class="title is-6 "> Networking Essentials - Cisco Networking Academy | IADT, Dublin</p>
                </li>
                <br>
                <li>
                  <p class="title is-6 "> Raspberry Pi Certified Educator | Raspberry Pi Foundation </p>
                </li>
                 <br>
                <li>
                  <p class="title is-6 "> Certificate in Computing | CCT, Dublin </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- end of tile ancestor -->
      </div>
      <!-- end of tiles column -->
    </section>   
  </div>
</template>

<script>

export default {
  name: 'About',
  components: {
  },
  data(){
    return {
      experience: []
    }
  },
  mounted(){
      this.getAllExperience()
  },
  methods: {
      getAllExperience(){
          //built in vs importing Axios
          fetch('./data/experience.json')
          //take the json and return as JS object 
          .then(res => res.json())
          //catching the data from the JS object
          .then(data => {
              //console.log(data)
              this.experience = data
          })
      },
  }
}

</script>

<style scoped>

</style>
